.BuyGMXGLP {
  background: #102415;
  justify-content: space-between;
}

.BuyGMXGLP-container {
  padding: 0 0 3.1rem 0;
  width: 100%;
}

.BuyGMXGLP-container .section-title-content {
  justify-content: flex-start;
}
