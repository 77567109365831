.Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: var(--font-sm);
  border-radius: 3px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.7);
  background: linear-gradient(90deg, rgba(30, 34, 61, 0.9) 0%, rgba(38, 43, 71, 0.9) 100%);
  box-shadow: inset 0px 0px 30px 5px rgba(255, 255, 255, 0.01);
}

.Tab-option-icon {
  margin-right: 0.465rem;
  opacity: 0.7;
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  cursor: pointer;
}

.Tab.block .Tab-option:hover {
  color: rgba(255, 255, 255, 1);
  background: linear-gradient(90deg, rgba(35, 57, 172, 1) 0%, rgba(43, 59, 147, 1) 100%);
}

.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  background: linear-gradient(90deg, #37783E 0%, #24A442 100%);
  color: rgba(255, 255, 255, 1);
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline {
  overflow-x: scroll;
  white-space: nowrap;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.Tab.inline::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1.5rem;
  font-size: var(--font-base);
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
}
