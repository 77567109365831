.TradeHistory-row {
  font-size: var(--font-sm);
  padding: 1.5rem;
  margin-bottom: 0.8rem;
}

.TradeHistory-time {
  font-size: 1.25rem;
  margin-bottom: 0.155rem;
}

.TradeHistory-item-link {
  display: inline-block;
}
