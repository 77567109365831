.DashboardV2-projects {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
}

@media (max-width: 900px) {
  .DashboardV2-projects {
    grid-template-columns: 1fr;
  }
}
